import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AvatarUploadComponent } from './components/avatar-upload/avatar-upload.component';

import { SharedModule } from '@athlete-x/shared';

import { AddressAutocompleteModule } from '../address-autocomplete/address-autocomplete.module';
import { AutocompletePickerModule } from '../autocomplete-picker';
import { IndustriesPickerModule } from '../industries-picker';
import { UploadResumeComponent } from './components/upload-resume/upload-resume.component';
import { TeamNetworksComponent } from './components/team-networks/team-networks.component';
import { IndustriesPickersListModule } from '../industries-pickers-list';
import { TeamNetworksListComponent } from './components/team-networks-list/team-networks-list.component';
import { DirectoryTypeToggleComponent } from '../directory-type-toggle/directory-type-toggle.component';
import { BasicInfoFormComponent } from './components/basic-info-form/basic-info-form.component';
import { WorkInfoFormComponent } from './components/work-info-form/work-info-form.component';
import { TeamGenderPipe } from '@athlete-x/shared/pipes/gender-parser.pipe';
import { PrivacySettingsComponent } from './components/privacy-settings/privacy-settings.component';
import { DirectoryGlobalVisiblityComponent } from '../directory-global-visiblity/directory-global-visiblity.component';
import { VisibilitySettingsModalComponent } from './components/visibility-settings-modal/visibility-settings-modal.component';
import { PublicAccessToggleComponent } from '../public-access-toggle/public-access-toggle.component';
import { MaskitoModule } from '@maskito/angular';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { JoinPipe } from '@athlete-x/shared/pipes/join.pipe';
import { PositionPickerModule } from '../position-picker';
import { CompaniesListModule } from '../companies-list/companies-list.module';
import { SchoolAutocompleteModule } from '../school-autocomplete';
import { SkillsFormComponent } from './components/skills-form/skills-form.component';
import { SchoolsListModule } from '../schools-list/schools-list.module';
import {IfProdDirective} from '@athlete-x/shared/directives/if-prod.directive';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        AutocompletePickerModule,
        IndustriesPickerModule,
        AddressAutocompleteModule,
        IndustriesPickersListModule,
        TeamGenderPipe,
        JoinPipe,
        MaskitoModule,
        PositionPickerModule,
        CompaniesListModule,
        SchoolAutocompleteModule,
        SchoolsListModule,
        IfProdDirective
    ],
    declarations: [
        AvatarUploadComponent,
        UploadResumeComponent,
        TeamNetworksComponent,
        TeamNetworksListComponent,
        DirectoryTypeToggleComponent,
        BasicInfoFormComponent,
        WorkInfoFormComponent,
        PrivacySettingsComponent,
        DirectoryGlobalVisiblityComponent,
        VisibilitySettingsModalComponent,
        PublicAccessToggleComponent,
        AccountSettingsComponent,
        SkillsFormComponent
    ],
    exports: [
        AvatarUploadComponent,
        UploadResumeComponent,
        TeamNetworksComponent,
        TeamNetworksListComponent,
        DirectoryTypeToggleComponent,
        BasicInfoFormComponent,
        WorkInfoFormComponent,
        PrivacySettingsComponent,
        DirectoryGlobalVisiblityComponent,
        VisibilitySettingsModalComponent,
        PublicAccessToggleComponent,
        AccountSettingsComponent,
        SkillsFormComponent
    ]
})
export class UpdateProfileModule {}
