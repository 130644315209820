import { Team, TeamType, UnregisteredTeam } from '@athlete-x/definitions';
import { isGeneralDomain } from '@athlete-x/shared/classes/DomainHelper';

export class TeamHelper {
    public static teamType(team: Team | UnregisteredTeam | any): TeamType {
        if (team?.college) {
            return TeamType.COLLEGE;
        }
        if (team?.high_school) {
            return TeamType.HIGH_SCHOOL;
        }
        return TeamType.GROUP;
    }

    public static getTeamFinder(slug?: string) {
        let finder: 'slug' | 'custom_domain';
        let value;

        const generalDomain = isGeneralDomain();
        if (generalDomain) {
            if (!slug) {
                return {} as any;
            }

            value = slug.replace('/', '');
            finder = 'slug';

        } else {
            value = globalThis?.location?.origin;
            finder = 'custom_domain';
        }

        return {
            finder,
            value
        };
    }
}
