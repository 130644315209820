export const jsonapi: any = {};

// Parse a HTTP Response using the JSONAPI format: http://jsonapi.org/format/
jsonapi.parse = function (response: any) {
    let json;

    // IF: Response is a string, try to parse as JSON string
    // ELSE IF: Response is a object, reassign to local variable
    // ELSE: Return whatever the input was
    if (isString(response)) {
        try {
            json = JSON.parse(response);
        } catch (error: any) {
            // IF: Not JSON format, return it
            // ELSE: Throw the error
            if (error.name === 'SyntaxError') {
                return response;
            }
            throw error;
        }
    } else if (isObject(response)) {
        json = response;
    } else {
        return response;
    }

    // IF: No required top-level JSON API members, return input
    if (isUndefined(json.data) && isUndefined(json.errors) && isUndefined(json.meta)) {
        return json;
    }

    // IF: Already parsed, return it
    if (json.jsonapi && json.jsonapi.parsed) {
        return json;
    }

    const parsed = deserialize(json);
    parsed.jsonapi.parsed = true;

    return parsed;
};

// Deserialize the JSONAPI formatted object
function deserialize(json: any) {
    let data;

    const includedMap: Record<string, any> = {};
    each(json.included, function (value: any) {
        const key = value.type + '-' + value.id;
        includedMap[key] = value;
    });

    if (isArray(json.data)) {
        data = map(
            json.data,
            function (record: any) {
                populateRelatedFields(record, includedMap);
                return flatten(record);
            }
        );
    } else if (isObject(json.data)) {
        populateRelatedFields(json.data, includedMap);
        data = flatten(json.data);
    }

    const deserialized: {
        data: any;
        jsonapi: any;
        meta?: any;
        errors?: any;
        links?: any;
    } = {
        data: data,
        jsonapi: json.jsonapi || {}
    };

    if (json.meta) {
        deserialized.meta = json.meta;
    }

    if (json.errors) {
        deserialized.errors = json.errors;
    }

    if (json.links) {
        deserialized.links = json.links;
    }

    return deserialized;
}

// Populate relations of the provided record from the included objects
function populateRelatedFields(record: any, includedMap: any, parents?: any) {

    // IF: Object has relationships, update so this record is listed as a parent
    if (record.relationships) {
        parents = parents ? parents.concat([record]) : [record];
    }

    each(
        record.relationships,
        function (relationship: any, property: any) {
            // IF: relationship describes non-record specific meta;
            // append relationship meta to the record
            if (relationship && isObject(relationship.meta)) {
                record.meta = record.meta || {};
                record.meta[property] = relationship.meta;
            }

            // IF: No relationship data, don't add anything
            if (!relationship.data) {
                return;
            }

            // IF: Relationship has multiple matches, create an array for matched records
            // ELSE: Assign relationship directly to the property
            if (isArray(relationship.data)) {
                record.attributes[property] = map(
                    relationship.data,
                    function (data: any) {
                        return getMatchingRecord(data, includedMap, parents);
                    }
                );
            } else {
                record.attributes[property] = getMatchingRecord(
                    relationship.data,
                    includedMap,
                    parents
                );
            }
        }
    );
}

// Retrieves the record from the included objects that matches the provided relationship
function getMatchingRecord(relationship: any, included: any, parents: any) {
    const circular = findWhere(
        parents,
        {
            id: relationship.id,
            type: relationship.type
        }
    );

    if (circular) {
        return relationship;
    }

    const key = relationship.type + '-' + relationship.id;
    const match = included[key];

    // IF: No match or match is the same as parent, return the relationship information
    if (!match) {
        return relationship;
    }

    populateRelatedFields(match, included, parents);

    // IF: relationship defined meta, merge with record provided meta
    let contextSpecificMeta = {};
    if (relationship && isObject(relationship.meta)) {
        contextSpecificMeta = extend({}, match.meta, relationship.meta);
    }

    return flatten(match, contextSpecificMeta);
}

// Flatten the ID of an object with the rest of the attributes on a new object
function flatten(record?: any, extraMeta?: any) {
    const meta = extend({}, record.meta, extraMeta);
    return extend(
        {},
        {links: record.links, meta: meta},
        record.attributes,
        {id: record.id, type: record.type}
    );
}

// A handful of helper functions_tiu
function isArray(value: any) {
    return Object.prototype.toString.call(value) === '[object Array]';
}

function isString(value: any) {
    return Object.prototype.toString.call(value) === '[object String]';
}

function isObject(value: any) {
    return Object.prototype.toString.call(value) === '[object Object]';
}

function isUndefined(value: any) {
    return value === undefined;
}

function each(collection: any, iterator: any) {
    let key;
    if (isArray(collection)) {
        for (key = 0; key < collection.length; key += 1) {
            iterator(collection[key], key);
        }
    } else if (isObject(collection)) {
        for (key in collection) {
            if (Object.prototype.hasOwnProperty.call(collection, key)) {
                iterator(collection[key], key);
            }
        }
    }
}

function map(collection: any, iterator: any) {
    const transformed: any = [];
    each(collection, function (value: any, key: any) {
        transformed.push(iterator(value, key) as never);
    });
    return transformed;
}

function every(collection: any) {
    let passes = true;
    each(collection, function (value: any) {
        if (value !== true) {
            passes = false;
        }
    });
    return passes;
}

function findWhere(collection: any, matches: any) {
    let match;
    each(collection, function (value: any) {
        const where = map(matches, function (shouldMatch: any, property: any) {
            return value[property] === shouldMatch;
        });
        if (every(where)) {
            match = value;
        }
    });
    return match;
}

function extend(...args: any) {
    const combined = Object(null),
        sources = Array.prototype.slice.call(args);
    each(
        sources,
        function (source: any) {
            each(source, function (value: any, key: any) {
                combined[key] = value;
            });
        }
    );
    return combined;
}
