export const ENDPOINTS = {
    self: 'users/self',
    updateEmail: 'users/update_email',
    publicProfile: 'users/public',
    updateUser: 'users/update',
    uploadAvatar: 'users/upload_avatar',
    uploadResume: 'users/upload_resume',
    acceptUser: 'users/accept',
    rejectUser: 'users/reject',

    usersDirectory: 'users/list',
    usersGlobalDirectory: 'users/global_list',
    userDetails: 'users/view',
    exportUsers: 'users/export',

    passwordReset: 'users/update_password',
    closeAccount: 'users/delete_account',

    leaveTeam: 'teams/leave',

    teamDetails: 'teams/retrive_data',
    teamAdminData: 'teams/team_admin_data',
    teamWaitlist: 'team/waitlist',
    teamLinks: 'team_links',
    teamLogoUpload: 'teams/upload_logo',
    teamBannerUpload: 'teams/upload_banner',
    joinToOtherTeam: 'teams/join',
    teamLinkUpdate: 'team_link/edit',
    teamLinkCreate: 'team_link/create',
    teamLinkDelete: 'team_link/delete',

    getHighSchools: 'high_schools/filter',
    getJobTitles: 'current_jobs/filter',
    getColleges: 'colleges/filter',
    getCompanies: 'companies/filter',

    userTeams: 'user_teams',

    getPosts: 'posts/list',
    createPost: 'posts/create',
    uploadAttachment: 'posts/upload_attachment',
    deletePost: 'posts/delete',

    sendPrivateMessages: 'messages/send_private',

    createUnregistredTeam: 'unregistered_teams/create',
    deleteUnregisteredTeam: 'unregistered_teams/delete',
    updateUnregisteredTeam: 'unregistered_teams/update',

    getSubscriptions: 'payments/subscriptions',
    menageSubscription: 'payments/manage_subscription',
    expireSubscriptionSession: 'checkouts/expire',
    checkoutsSession: 'checkouts/session',
    getPrices: 'prices',

    updateWorkPositions: 'users/update',
    updateSchools: 'users/update',
};

export const AUTOCOMPLETE_ENDPOINTS = {
    high_school: 'high_schools/filter',
    college: 'colleges/filter',
    interested_company: 'companies/filter',
    major: 'majors/filter',
    sport: 'sports/filter',
    group: 'groups/filter',
    positions: 'positions',
    company: 'companies/filter',
    title: 'current_jobs/filter',
    mentor: 'user_for_mentors/filter',
    mentee: 'user_for_mentees/filter'
};
