import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TeamStore, UserStore } from '@athlete-x/stores';
import { Observable, of, switchMap, take, tap } from 'rxjs';
import { User, ENDPOINTS, Team, UserType, WorkPosition } from '@athlete-x/definitions';
import { UtilitiesService } from './utilities.service';
import { AlertController } from '@ionic/angular';
import { map } from 'rxjs/operators';

const ERRORS: Record<string, string> = {
    'last_team': 'You need to be member of at least one team!'
};

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private _http: HttpClient,
        private _user: UserStore,
        private _team: TeamStore,
        private _utilities: UtilitiesService,
        private _alertController: AlertController
    ) {
    }

    load(): Observable<User> {
        return this._user.isLoaded$
            .pipe(
                take(1),
                switchMap((profile) => {
                    if (!profile) {
                        return this._load();
                    }

                    return this._user.user$
                        .pipe(take(1));
                })
            );
    }

    public loadById(id: number) {
        return this._user.id$
            .pipe(
                take(1),
                switchMap((currentUserId) => {
                    if (currentUserId === id) {
                        return this._load();
                    }

                    return this._http.get<User>(ENDPOINTS.userDetails, {
                        params: {
                            user_id: id
                        }
                    });
                }),
                map((data) => this._mapUserTeamsToTeams(data))
            );
    }

    public loadPublicProfile(externalId: string) {
        return this._http.get<User>(ENDPOINTS.publicProfile, {
            params: { external_id: externalId }
        }).pipe(
            map((data) => this._mapUserTeamsToTeams(data)),
            map((data) => this._parseWorkPosition(data))
        );
    }

    private _mapUserTeamsToTeams(data: User) {
        data.teams = data.teams.map((team) => {
            const userTeam = data.user_teams.find((t) => t.team.id === team.id);
            const jerseyNumber = userTeam ? userTeam.jersey_number : team.jersey_number;
            return {
                ...team,
                position: userTeam ? userTeam.position : team.position,
                jersey_number: jerseyNumber ? '#' + jerseyNumber : undefined
            } as Team;
        });

        return data;
    }

    reload() {
        return this._load();
    }

    _load() {
        return this._http.get<User>(ENDPOINTS.self)
            .pipe(
                map((data) => this._mapUserTeamsToTeams(data)),
                tap((profile) => {
                    this._user.setUser(profile);
                    this._team.fillFromProfile(profile);
                })
            );
    }

    public updateProfile(data: any) {
        const {
            website,
            twitter,
            tiktok,
            snapchat,
            instagram,
            email,
            phone,
            linkedin,
            hometown,
            user_colleges,
            user_high_schools,
            ...restParms
        } = data;

        return this._http.patch<User>(ENDPOINTS.updateUser, {
            ...this._utilities.filterEmptyFromObject(restParms),
            hometown,
            website,
            twitter,
            tiktok,
            snapchat,
            instagram,
            email,
            phone,
            linkedin,
            user_colleges,
            user_high_schools
        })
            .pipe(switchMap((response) => this._onUpdated(response)));
    }

    public uploadAvatar(data: any) {
        return this._http.patch(ENDPOINTS.uploadAvatar, { ...data })
            .pipe(switchMap(() => this.reload()));
    }

    public uploadResume(data: any) {
        return this._http.patch(ENDPOINTS.uploadResume, { ...data })
            .pipe(switchMap(() => this.reload()));
    }

    public passwordReset(params: { password: string, password_confirmation: string }) {
        return this._http.post(ENDPOINTS.passwordReset, { ...params });
    }

    private _onUpdated(profile: User) {
        if (profile) {
            this._user.setUser(profile);
            this._team.fillFromProfile(profile);
            return of(true);

        } else {
            return this.reload();
        }

    }

    public joinToOtherTeam(team_id: number, params?: any) {
        return this._http.post(ENDPOINTS.joinToOtherTeam, {
            team_id,
            ...params
        })
            .pipe(switchMap(() => this.reload()));
    }

    private _leaveTeamRequest(teamId: number) {
        return this._http.post(ENDPOINTS.leaveTeam, {
            team_id: teamId
        })
            .pipe(switchMap(() => this.reload()));
    }


    public leaveTeam(team: Team) {
        const buttons = [
            {
                text: 'Cancel',
                role: 'cancel',
            },
            {
                text: 'OK',
                role: 'confirm',
                handler: () => this._leaveTeamHandler(team)
            }
        ];

        this._alertController
            .create({
                subHeader: 'Leave team alert',
                message: 'Are you sure that you want leave: ' + team.name + '?',
                buttons,
            })
            .then(alert => alert.present());

    }

    private _leaveTeamHandler(team: Team) {
        return this._leaveTeamRequest(team.id)
            .subscribe({
                next: () => this._utilities.presentToast('You left team: ' + team.name),
                error: (error: string) => this._utilities.presentAlert({
                    subHeader: 'You can\'t leave this team',
                    message: ERRORS[error] ? ERRORS[error] : error,
                    buttons: ['Ok'],
                })
            });
    }

    public closeAccount() {
        return this._http.post(ENDPOINTS.closeAccount, {});
    }

    public changeEmail(email: string) {
        return this._http.post<User>(ENDPOINTS.updateEmail, {
            email
        })
            .pipe(
                tap(() => this._user.fillUser({ email }))
            );
    }

    public editUserTeamData(params: {
        team_id: number,
        user_id?: number,
        user_type?: UserType,
        position?: string,
        jersey_number?: number
    }) {
        return this._http.patch(ENDPOINTS.userTeams, {
            ...params
        })
            .pipe(switchMap(() => this.reload()));
    }

    private _parseWorkPosition(user: User) {
        const { work_positions } = user;
        return {
            ...user,
            work_positions: this._sortWorkPosition(work_positions)
        };
    }

    private _sortWorkPosition(work_positions: Array<WorkPosition>) {
        if (!work_positions?.length) {
            return null;
        }

        const currentCompany: Array<WorkPosition> = [];
        const pastCompanies: Array<WorkPosition> = [];

        work_positions.map((e: WorkPosition) => {
            if (e.currently_active) {
                currentCompany.push(e);
            } else {
                pastCompanies.push(e);
            }
        });

        return [
            ...currentCompany,
            ...pastCompanies
        ];
    }
}
